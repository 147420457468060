import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import FormUsuarios from "../../components/formUsuarios";
import App from "../../layout/app";

const CadastroUsuario = () => {
  // Dados de exemplo para a lista

  return (
    <App>
      <FormUsuarios />
    </App>
  );
};

export default CadastroUsuario;
